@font-face {
  font-family: monospacedFont;
  src: url(./nimbusmono-bold.otf);
}

body {
  font-family: monospacedFont;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  overflow: hidden;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

@keyframes sos {
  0% {font-size: 9vw;}
  100% {font-size: 15vw;}
}

@keyframes sosI {
  0% {font-size: 15vw;}
  100% {font-size: 9vw;}
}

.btns{
  padding: 1em 2.1em 1.1em;
  border-radius: 3px;
  margin: 8px 8px 8px 8px;
  display: inline-block;
  font-family: sans-serif;
  font-weight: 800;
  font-size: .95em;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
  position: relative;
  margin: 0 auto;
  opacity: 1;
  width: 100px;
}

#allButtons{
  display: flex;
}

#bigContainer{
  width: 60%;
  margin: 0 auto;
}

.btn:hover, .btn:focus {
  opacity: 0.8;
}

#price{
  width: 60%;
  height: 20%;
  font-size: 9vw;
  margin-bottom: 2%;
}

.majorContainer {
  display: flex;
  width: 30%;
  justify-content: space-between;
  margin: 0 auto;
}

#littleContainer{
  opacity: 1;
}

@keyframes bruhI {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes bruh {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.buttons{
  display: inline-block;
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  background-size: cover;
  font-size: 2.0em;
  cursor: pointer;
  margin: 0 auto;
}

#btnPlus{
  background-image: url(./plus-sign-in-a-black-circle.svg);
}

#btnMinus{
  background-image: url(./minus-symbol-inside-a-circle.svg);
}

.input{
  font-size: 25px;
}

@media screen and (max-width: 824px) and (max-height: 412px) {
  #littleContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .majorContainer{
    width: 90%;
    margin: 0 auto;
  }
  #price{
    margin: 0 auto;
    font-size: 10vw;
  }
  @keyframes sos {
    0% {font-size: 10vw;}
    100% {font-size: 13vw;}
  }
  @keyframes sosI {
    0% {font-size: 13vw;}
    100% {font-size: 10vw;}
  }
}

@media screen and (max-width: 769px) and (max-height: 1025px) {
  .btns{
    margin-bottom: 5px;
    width: 150px;
  }
  #allButtons{
    flex-direction: column;
    margin: 0 auto;
    width: 90%;
  }
  .majorContainer{
    width: 90%;
  }
  #bigContainer{
    margin-top: -10%;
    width: 70%;
  }
  #price{
    margin: 0 auto;
    font-size: 15vw;
  }
  @keyframes sos {
    0% {font-size: 15vw;}
    100% {font-size: 20vw;}
  }
  @keyframes sosI {
    0% {font-size: 20vw;}
    100% {font-size: 15vw;}
  }
}

  @media screen and (min-width: 520px) and (max-width: 755px) {
    #price{
      margin: 0 auto;
      margin-bottom: 5%;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 897px) {
    .majorContainer{
      width: 90%;
    }
  }

  @media screen and (min-width: 897px) and (max-width: 1050px) {
    .majorContainer{
      width: 50%;
    }
  }

  @media screen and (max-width: 737px) and (max-height: 415px) {
  #bigContainer{
    margin-top: 0%;
  }
  #modify{
    margin-left: 2%;
    margin-right: 2%;
  }
  #allButtons{
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (max-width: 415px) and (orientation: portrait) {
  #littleContainer{
    display: flex;
    flex-direction: column;
  }
  #price{
    margin: 0 auto;
    font-size: 20vw;
  }
}

@media only screen and (max-width: 897px) and (orientation: landscape) {
  #littleContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 110%;
  }
  .majorContainer{
    margin: 0 auto;
  }
  #cont1{
    width: 100%;
  }
  #cont2{
    width: 100%;
  }
  #price{
    margin: 0 auto;
    font-size: 10vw;
  }
  @keyframes sos {
    0% {font-size: 10vw;}
    100% {font-size: 13vw;}
  }
  @keyframes sosI {
    0% {font-size: 13vw;}
    100% {font-size: 10vw;}
  }
}

@media only screen and (max-height: 414px) {
  #bigContainer{
    width: 100%;
  }
  .majorContainer{
    width: 60%;
  }
  #price{
    font-size: 10vw;
  }
  @keyframes sos {
    0% {font-size: 10vw;}
    100% {font-size: 13vw;}
  }
  @keyframes sosI {
    0% {font-size: 13vw;}
    100% {font-size: 10vw;}
  }
}
